<template>
<v-container class="text-wrap" :class="screenClass">
    <v-row>
        <v-col cols="12" :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp }">
            <template v-if="sectionType != 'audio'">
                <v-card class="module-section" :class="{ 'pa-6': $vuetify.breakpoint.mdAndUp }" v-if="sectionType != 'audio'">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex pa-2 justify-end align-center">
                                <v-btn text class="zindex-2 p-absolute top25 left15 pa-1" @click="back()" v-if="$vuetify.breakpoint.smAndUp">
                                    <v-icon>mdi-chevron-left</v-icon>
                                    Back
                                </v-btn>

                                <meo-favourite-button :section="section" :oldContact="contact"></meo-favourite-button>
                            </div>

                            <template v-if="$vuetify.breakpoint.mdAndUp">
                                <div class="d-flex align-center">
                                    <div class="meo-card-image">
                                        <div class="image-inner">
                                            <div class="image" :style="backgroundImage"></div>
                                        </div>
                                    </div>

                                    <h1 v-if="section.data.sectionName === 'Reading'" class="reading-heading py-4">
                                        {{ heading }}
                                    </h1>
                                </div>
                            </template>

                            <template v-else>
                                <h1 v-if="section.data.sectionName === 'Reading'" class="reading-heading py-4">
                                    {{ heading }}
                                </h1>
                            </template>

                            <div v-html="text"></div>
                        </v-col>
                    </v-row>
                </v-card>
            </template>

            <template v-else>
                <v-container :class="{ 'pa-0': $vuetify.breakpoint.mdAndUp }">
                    <v-row>
                        <v-col>
                            <v-card class="module-section" :class="{ 'pa-6': $vuetify.breakpoint.mdAndUp }">
                                <v-row>
                                    <meo-meditation-player @back="back" :contact="contact" @progressCheck="progressCheck" :section="section" :setFloat="true" :buttonControl="buttonControl" />
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-col>
    </v-row>

    <v-container ref="bot" :class="{ 'pa-0': $vuetify.breakpoint.mdAndUp }">
        <v-row>
            <v-col cols="12" :class="[$vuetify.breakpoint.mdAndUp ? 'd-flex pl-0' : 'pt-3']">
                <transition name="slide-fade">
                    <!-- <v-btn class="test-button" v-if="showButton" :block="$vuetify.breakpoint.smAndDown" x-large dark color="dark" @click="completeSection()">Complete: {{ name }}
                        <v-icon class="ml-2" color="warning">mdi-star</v-icon>
                    </v-btn> -->

                    <v-btn class="test-button" v-if="showButton" :block="$vuetify.breakpoint.smAndDown" x-large dark color="dark" @click="completeSection()">Complete
                        <v-icon class="ml-2" color="warning">mdi-star</v-icon>
                    </v-btn>
                </transition>

                <div v-if="!showButton && screens.length !== 1" style="height:52px"></div>
            </v-col>
        </v-row>
    </v-container>
</v-container>
</template>

<script>
// Components
import MeoMeditationPlayer from "@/components/misc/meo-meditation-player.vue";
import MeoFavouriteButton from "@/components/misc/meo-favourite-button.vue";

// Services
import Contact from "@/modules/meo-module/contact";

export default {
    name: "module-screen",

    meta() {
        // return { titleTemplate: `${(this.section.data && this.section.data.displayTitle) || this.section.title} | %s` };
        return { titleTemplate: `${this.module.title} | %s` };
    },

    props: {
        sections: {
            type: Array,
        },
        screens: {
            type: Array,
        },
        progressDoc: {
            type: Object,
        },
        module: {
            type: Object,
        },
        contact: {
            type: Object,
        },
    },

    components: {
        MeoMeditationPlayer,
        MeoFavouriteButton,
    },

    data() {
        return {
            section: {},
            loading: false,
            notes: "",
            buttonControl: false,
        };
    },

    computed: {
        heading() {
            if (this.section?.data?.heading) {
                return this.section.data.heading;
            } else {
                return "";
            }
        },
        title() {
            return (
                (this.section && this.section.data && this.section.data.displayTitle) ||
                (this.section && this.section.title)
            );
        },
        name() {
            return (this.section && this.section.data && this.section.data.sectionName) || "";
        },
        text() {
            return this.section && this.section.data && this.section.data.text;
        },
        sectionType() {
            return this.section && this.section.data && this.section.data.sectionType;
        },
        currentIndex() {
            return this.sections.findIndex((section) => section._id == this.section._id);
        },
        showButton() {
            let showButton = false;
            if (this.sectionType != "audio") {
                showButton = true;
            } else {
                if (this.buttonControl === true) {
                    showButton = true;
                }
            }

            return showButton;
        },
        backgroundImage() {
            if (this.module?.data?.image || this.module?.image) {
                return {
                    backgroundImage: `url(${this.$fluro.asset.imageUrl(this.module.data.image || this.module.image)})`,
                };
            }

            return "";
        },
        image() {
            return this.$fluro.asset.imageUrl(this.module.data.image || this.module.image);
        },
        screenClass() {
            // [sectionType == 'audio' ? 'mb-16' : 'pa-0 moreMargin']
            if (this.$vuetify.breakpoint.mdAndUp) return "pa-3 full-width";
            if (this.sectionType == "audio") return "full-width";
            if (this.sectionType != "audio" && this.module.items.length > 1) return "pa-0 full-width";
            if (this.module.items.length === 1) return "pa-0 full-width";
            return "";
        },
    },

    methods: {
        async init() {
            this.loading = true;
            this.section = this.sections.find((section) => section._id === this.$route.params.screenId);
            this.loading = false;
        },
        goTo() {
            let index = this.currentIndex + 1;
            let next = this.sections[index];

            if (next) {
                this.$emit("next", next);
            } else {
                this.$emit("notes");
            }
        },
        progressCheck(show) {
            this.buttonControl = show;

            setTimeout(() => {
                if (this.$vuetify.breakpoint.smAndDown) {
                    this.$refs.bot.scrollIntoView({ behavior: "smooth" });
                }
            }, 500);
        },
        hasScreen(currentModule) {
            // check if the screens array EXISTS in current module
            if (currentModule?.screens) {
                // check if this.section exists in screens array
                let filteredCurrentModuleScreens = currentModule.screens.filter(
                    (item) => item._id === this.section._id
                );
                // if this.section already exists in screens array, do nothing
                if (filteredCurrentModuleScreens && filteredCurrentModuleScreens.length) {
                    return;
                } else {
                    // if this.section does NOT exist in screens array, push current section to screens array
                    currentModule.screens.push(this.section);
                    return;
                }
            } else {
                // screens array does NOT exist, create screens array with current section
                currentModule.screens = [this.section];
                return;
            }
        },
        async completeSection() {
            // onClick section completion button
            // Check if user's progressDoc.module exists

            if ((this.contact && this.contact._id) || this.$meo.user) {
                if (this.progressDoc?.data?.modules) {
                    // check if current module is in the user's progressDoc.module
                    let index = this.progressDoc.data.modules.findIndex((item) => item.module._id == this.module._id);

                    if (index !== -1) {
                        this.hasScreen(this.progressDoc.data.modules[index]);
                    } else {
                        // current module is NOT in user's progressDoc.module
                        this.progressDoc.data.modules.push({
                            module: this.module,
                            screens: [this.section],
                            notes: "",
                        });
                    }
                } else if (this.progressDoc) {
                    this.progressDoc.data = {
                        modules: [{
                            module: this.module,
                            screens: [this.section],
                            notes: "",
                        }, ],
                    };
                }
                console.log("updated progressDoc", this.progressDoc);
                this.$emit("update", this.progressDoc);
            } else {
                this.$localProgress.localOnComplete(this.module, this.section);
            }

            this.goTo();
        },
        back() {
            if (this.$route.name == "module.menu") {
                this.$router.push({ name: "modules" });
            } else if (this.module.items.length <= 1) {
                this.$router.push({ name: "modules" });
            } else {
                this.$router.push({
                    name: "module.menu",
                    params: { id: this.$route.params.id },
                });
            }
        },
    },

    async created() {
        await this.init();
    },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
    transition: all 0.5s;
}
/* .fade-leave-active below version 2.1.8 */
.slide-fade-enter {
    opacity: 0;
}

.width-80 {
    max-width: 80% !important;
}

.module-image {
    width: 400px;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.image-wrapper-screen {
    width: 100%;
    max-height: 300px;
    display: flex;
    justify-content: center;
}
.reading-heading {
    @media (max-width: 960px) {
        margin-bottom: none !important;
        text-align: left;
    }
}

.meo-card-image {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background: var(--v-primary-base);
    flex-shrink: 0;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 15px;

    .image-inner {
        background: #ffffff;
        border-radius: 100%;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .image {
            border-radius: 100%;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
</style>
